/***********************************************
Definicion de psicionamientos de componentes input
Edwin Espinal
AEInformatica 2019-06
***********************************************/

/**************************** Botones, submit, reset  y boton*/
.boton .btn{
    width: 100%;
}

