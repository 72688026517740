.LoginBorde{
    border-radius: 18px 18px 18px 18px;
    -moz-border-radius: 18px 18px 18px 18px;
    -webkit-border-radius: 18px 18px 18px 18px;
}

.Login{
    border: 0px solid #000000;
    text-align: center;
}
.LoginText{
    margin-top: 20px;
    font-size: 1rem;
}
.LoginDivisor{
    height: 5px;
    background: #fff;
    margin: 15px 0;
}

.LoginBotonReg  button{
    background: #f26334 !important;
    width: 80%;
}

.msgOK{
    color:#000000;
}